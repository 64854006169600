//
// symbol
//

// Base
.symbol {
    display: inline-block;;
    flex-shrink: 0;
    position: relative;
    @include border-radius($border-radius);

    // Label
    .symbol-label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: $dark-75;
        background-color: $gray-100;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        @include border-radius($border-radius);
    }

    // Badge
    .symbol-badge {
        position: absolute;
        border: 2px solid $white;
        border-radius: 100%;
        top: 0;
        right: 0;
    }

    // Image
    > img {
        display: flex;
        @include border-radius($border-radius);
    }

    // Circle style
    &.symbol-circle {
        @include border-radius(50%);

        > img {
            @include border-radius(50%);
        }

        .symbol-label {
            @include border-radius(50%);
        }
    }

    // Theme colors
    @each $color, $value in $theme-colors {
        // Default style
        &.symbol-#{$color} {
            .symbol-label {
                background-color: $value;
                color: theme-inverse-color($color);
            }
        }

        // Light style
        &.symbol-light-#{$color} {
            .symbol-label {
                background-color: theme-light-color($color);
                color: $value;
            }
        }
    }

    // Responsize sizes
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

            @each $name, $value in $symbol-sizes {
                @if ($name == 'default') {
                    @include symbol-size($value);
                } @else {
                    &.symbol#{$infix}-#{$name} {
                        @include symbol-size($value);
                    }
                }
            }
        }
    }
}

// symbol Group
.symbol-group {
    display: flex;
    align-items: center;

    .symbol {
        position: relative;
        z-index: 0;
        margin-left: -10px;
        border: 2px solid $white;
        transition: all 0.3s ease;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            transition: all 0.3s ease;
            z-index: 1;
        }
    }

    &.symbol-hover {
        .symbol {
            cursor: pointer;
        }
    }
}

// symbol List
.symbol-list {
    display: flex;
    align-items: center;

    .symbol {
        margin-right: 0.5rem;

        &:last-child {
            margin-right: 0;
        }
    }
}
